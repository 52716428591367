import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
function HeroVideo({ urlVideo }) {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="w-full relative content_video flex justify-center">
        <video
          playsInline
          autoPlay
          muted
          loop
          className="w-full md:h-full object-cover"
        >
          <source src={urlVideo} type="video/mp4" />
        </video>
        <div className="flex md:flex-row flex-col md:w-4/5 mx-auto absolute bottom-[0px] lg:bottom-[200px] md:text-start text-center text-white">
          <div className="w-full text-center md:mt-20 mt-[450px] px-10">
            <h1 className="text-white text-center">
              {rpdata?.dbSlogan?.[0].slogan}
            </h1>
            <p className="text-white text-center">
              {rpdata?.dbValues?.[1].description}
            </p>
            <ButtonContent btnStyle="three" />
          </div>
        </div>
      </div>
    </div>
  );
}
export default HeroVideo;
